.app-header {
    background-color: #003366; /* Darker shade of blue */
    color: white;
    padding: 1rem;
    text-align: center;
  }

  .whatsapp-link{
    text-decoration: none;
    color: white;
  }
  
  .app-header nav ul {
    list-style: none;
    padding: 0;
  }
  
  .app-header nav ul li {
    display: inline;
    margin: 0 1rem;
  }
  
  .app-header nav ul li a {
    color: white;
    text-decoration: none;
  }
  